.body-bg-full .page-container.bg-phishstrike-black{
    background-color: #141414;
}

.body-bg-full .page-container {
    background-color: rgba(90,60,40,.4);
}

a {
    color: #fff;
}

a:focus, a:hover {
	color: #9a9a9a;
}

.new-input-white {
	width: 100%;
    border: none;
    padding: 10px 20px;
    outline: none;
    text-align: center;
}

.text-muted {
    color: white;
}
.checkbox-custom input[type=checkbox]+label.checkbox-muted:before {
    border-color: white;
}
